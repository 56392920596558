import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: "",
        avatar: "",
        username: "",
        buttons: [],
        keepAlive: [],
        menu: []
    },
    getters: {
        scenic_order_status() {
            //0待支付1已支付2售后中3已退款4已取消5待评价6已完成
            return {
                0: {
                    label: "待支付",
                    value: 0,
                    type: "info"
                },
                1: {
                    label: "已支付",
                    value: 1,
                    type: "success"
                },
                2: {
                    label: "售后中",
                    value: 2,
                    type: "warning"
                },
                3: {
                    label: "已退款",
                    value: 3,
                    type: "danger"
                },
                4: {
                    label: "已取消",
                    value: 4,
                    type: "danger"
                },
                5: {
                    label: "待评价",
                    value: 5,
                    type: "warning"
                },
                6: {
                    label: "已完成",
                    value: 6,
                    type: "success"
                }
            }
        },
        scenic_refund_status() {
            //0待退款1已退款2拒绝退款
            return {
                0: {
                    label: "待退款",
                    value: 0,
                    type: "info"
                },
                1: {
                    label: "已退款",
                    value: 1,
                    type: "success"
                },
                2: {
                    label: "拒绝退款",
                    value: 2,
                    type: "danger"
                }
            }
        },
        scenic_type() {
            //1.南昌周边亲子游2.游点不一样
            return {
                1: {
                    label: "南昌周边亲子游",
                    value: 1,
                    type: "primary"
                },
                2: {
                    label: "游点不一样",
                    value: 2,
                    type: "success"
                }
            }
        },
        is_hot() {
            //是否热门爆款1是0否
            return {
                1: {
                    label: "是",
                    value: 1,
                    type: "success"
                },
                0: {
                    label: "否",
                    value: 0,
                    type: "primary"
                }
            }
        }
    },
    mutations: {
        login(state, payload) {
            state.token = payload.token;
        },
        logout(state) {
            state.token = "";
        },
        setAdminInfo(state, payload) {
            state.avatar = payload.avatar;
            state.username = payload.username;
        },
        setButtons(state, payload) {
            state.buttons = payload;
        },
        setKeepAlive(state, alive) {
            state.keepAlive = alive
        },
        setMenu(state, menu) {
            state.menu = menu
        }
    },
    actions: {
        setKeepAlive(ctx, keepalice) {
            ctx.commit('setKeepAlive', keepalice)
        }
    },
    modules: {
    },
    plugins: [
        createPersistedState()
    ]
})
